.article_teaser_wrapper {
  height: 100%;
}

.article_teaser {
  position: relative;
  z-index: 2;
  &__vertical_overlay {
    display: block;
    position: relative;

    .article_teaser__article-image {
      &:after {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.0) 40%, rgba(0, 0, 0, 0.8) 100%);
  
        @include xxx(xs, sm, smm) {
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.0) 10%, rgba(0, 0, 0, 1) 100%);
        }
      }
    }

    .black_gradient {
      display: none;
    }

    .article_teaser__img_bottom {
      bottom: 1em;
      left: 1em;
      position: absolute;
      width: calc(100% - 1em);

      .article_teaser__article_supertitle {
        bottom: 0em;
        left: 0em;
        position: relative;
      }
  
      .article_teaser__article_supertitle span{
        color: white;
      }

      .article_teaser__title_text {
        color: white;
        font-family: Poppins;
        font-weight: 700;
        font-size: 1.05em;
        line-height: 1.4em;
        text-transform: uppercase;
        @include xxx(xs, sm, smm) {
          font-size: 0.9em;
        }
      }

      .article_teaser__subtitle_text {
        color: white;
      }
    }
  }

  &__vertical {
    display: block;

    .article_teaser___horizontal_box2 {
      padding: 1em;
    }
  }

  &__veritcal_switchable {
    display: block;

    .article_teaser___horizontal_box2 {
      padding: 1em;
    }

    @include xxx(xs, sm) {
      display: grid;
      grid-template-columns: 40% 1fr;
      grid-template-rows: max-content;
      grid-template-areas: "a b";
      column-gap: 1em;
      .article_teaser___horizontal_box2 {
        padding: 1em 1em 1em 0;
      }
      .article_teaser__title_link {
        padding-top: 0em;
        display: block;
        overflow: hidden;
      }
      .article_teaser__title_text {
        line-height: 1.4em;
        font-size: 1.05em;
        text-transform: uppercase;
      }
    }
  }

  &__horizontal {
    display: grid;
    grid-template-columns: 30% 1fr;
    grid-template-rows: max-content;
    grid-template-areas: "a b";
    column-gap: 1em;

    .article_teaser___vertical_box2 {
      padding: 1em 1em 1em 0;
    }

    .article_teaser__title_link {
      padding-top: 0;
    }

    @include xxx(xs, sm, smm) {
      .article_teaser__article_supertitle {
        display: none !important;
      }
      .article_teaser__title_text {
        line-height: 1.3em;
        font-size: 0.9em;
        text-transform: uppercase;
      }
    }
  }

  &__horizontal_switchable {
    display: grid;
    grid-template-columns: 30% 1fr;
    grid-template-rows: max-content;
    grid-template-areas: "a b";
    column-gap: 1em;

    .article_teaser___vertical_box2 {
      padding: 1em 1em 1em 0;
    }
    .article_teaser__title_link {
      padding-top: 0;
    }

    @include xxx(xs, sm, smm, md) {
      .article_teaser__title_link {
        padding-top: 0.3em;
      }
    }
  }

  &__article {
    position: relative;
    box-sizing: border-box;
    color: $white-color;
    text-decoration: none;
    overflow: hidden;
    width: 100%;

    background-image: url("/static/sn/images/photo.svg");
    background-size: 20%;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__article-image {
    width: 100%;
    background-size: cover;
    background-position: center;
    transition: all 0.5s;
    transition-timing-function: ease;
  }

  &__title_link {
    box-sizing: border-box;
    width: 100%;
    font-size: 1.05em;
    line-height: 1.2em;
    margin: 0;
    padding-top: 0.3em;
    text-decoration: none;
    color: $black-color;
    font-weight: inherit;
    display: block;
    overflow: hidden;

    h3 {
      margin: 0;
      padding: 0;
      font-family: Poppins;
      font-weight: 700;;
      font-size: 1.05em;
      line-height: 1.4em;
      padding-bottom: 0.2em;
      padding-right: 1em;

      br {
        display: block;
        content: '*';
        margin-bottom: 0.3em;
      }
    }
  }

  &__title_text {
    font-family: Poppins;
    font-weight: 700;
      font-size: 0.9em;
      line-height: 1.3em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
  }

  &__subtitle_text {
      font-size: 1.05em;
      line-height: 1.4em;
    font-family: Poppins;
    font-weight: 300;
  }

  &__author_name {
    color: $darker-50-color;
    font-size: 0.8em;
    padding-top: 0.3em;

    a {
      font-size: 0.8em;
      color: $darker-50-color;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: $darker-80-color;
      }
    }
  }

  &__author {
    display: inline-block;
  }

  &__article_supertitle {
    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.dark .article_teaser {
  &__author_name {
    color: $lighter-90-color;

    a {
      color: $lighter-90-color;

      &:hover {
        color: white;
      }
    }
  }
}